import {
  AiOutlineGithub,
  AiOutlineLinkedin,
  AiOutlineMail,
  AiOutlinePaperClip,
} from "react-icons/ai";
import React from "react";

const navigation = [
  {
    name: "GitHub",
    href: "https://github.com/JamesWingert",

    icon: <AiOutlineGithub className="h-7 w-7 mr-1" />,
  },
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/in/james-wingert/",

    icon: <AiOutlineLinkedin className="h-7 w-7 mr-1" />,
  },
  {
    name: "Resume",
    href: "https://drive.google.com/file/d/1Hr1-OmVKs-M8wd_Pham_bLxZW232VOEj/view?usp=sharing",

    icon: <AiOutlinePaperClip className="h-7 w-7 mr-1" />,
  },
];

export default function Footer() {
  return (
    <footer className="bg-base-100">
      <div className="max-w-7xl mx-auto py-12 px-6 sm:px-8 md:flex md:items-center md:justify-between lg:px-10">
        <div className="flex justify-center space-x-6 md:order-2">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-primary hover:text-primary-content"
            >
              <span className="sr-only">{item.name}</span>
              {item.icon}
            </a>
          ))}
        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <a href="mailto:jamespwingert@gmail.com">
            <p className="text-center text-base text-primary flex hover:text-primary-content items-center">
              <AiOutlineMail className="h-7 w-7 mr-1" />
              JamesPWingert@gmail.com
            </p>
          </a>
        </div>
      </div>
    </footer>
  );
}
