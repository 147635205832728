import js from '../assets/js.svg';
import rt from '../assets/react.png';
import py from '../assets/py.png';
import nt from '../assets/nxt.png';
import dk from '../assets/dk.png';
import e from '../assets/e.png';
import psql from '../assets/psql.png';
import ts from '../assets/ts.svg';
import tw from '../assets/tw.png';
import ql from '../assets/ql.png';
import aws from '../assets/aws.png';
import dotnet from '../assets/dotnet.png';
import React from 'react';

const skill = [
  {
    name: 'C#/Dotnet',
    imageUrl: `${dotnet}`,
  },
  {
    name: 'JavaScript',
    imageUrl: `${js}`,
  },
  {
    name: 'ReactJS',
    imageUrl: `${rt}`,
  },
  {
    name: 'NextJS',
    imageUrl: `${nt}`,
  },
  {
    name: 'TypeScript',
    imageUrl: `${ts}`,
  },
  {
    name: 'Python',
    imageUrl: `${py}`,
  },
  {
    name: 'GraphQL',
    imageUrl: `${ql}`,
  },
  {
    name: 'PostgreSQL',
    imageUrl: `${psql}`,
  },
  {
    name: 'Docker',
    imageUrl: `${dk}`,
  },
  {
    name: 'Node',
    imageUrl: `${e}`,
  },
  {
    name: 'TailwindCSS',
    imageUrl: `${tw}`,
  },
  {
    name: 'AWS',
    imageUrl: `${aws}`,
  },
];

export default function Skills() {
  return (
    <div id='skills' className='relative pt-20'>
      <div className='max-w-7xl mx-auto px-4 text-center sm:px-6 lg:px-8 '>
        <div className='space-y-8 sm:space-y-12'>
          <div className='space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl'>
            <h2 className='text-3xl font-semibold text-primary tracking-widest sm:text-6xl font-oleo uppercase'>
              Toolbox
            </h2>
            <p className='text-xl text-neutral'>
              These are the tools and technologies that I have recently been
              using. I am always looking to learn more, so if your project
              requires a different technology, please let me know!
            </p>
          </div>
          <ul className='mx-auto grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 bg-base-300 py-8'>
            {skill.map((item) => (
              <li key={item.name}>
                <div className='space-y-4'>
                  <img
                    className='mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24 bg-base-300'
                    src={item.imageUrl}
                    alt=''
                  />
                  <div className='space-y-2'>
                    <div className='text-lg font-medium text-neutral'>
                      <h3>{item.name}</h3>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
