/* eslint-disable react/prop-types */
import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { BiError } from "react-icons/bi";
import React from "react";

export default function Alert(props: {
  message:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) {
  const [show, setShow] = useState(true);

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div aria-live="assertive" className="relative ">
        <div className="w-full flex flex-col items-center ">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-info shadow-lg rounded-lg pointer-events-auto ring-1 ring-primary ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start text-center">
                  <div className="flex-shrink-0">
                    <BiError
                      className="h-8 w-8 text-primary"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0  flex-1 pt-0.5">
                    <p className="text-md  font-medium text-primary">
                      {props.message}
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className=" rounded-md inline-flex text-primary hover:bg-base-100 outline hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-8 w-8" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
