import React from "react";
import me3 from "../assets/me3.png";

export default function Hero() {
  return (
    <>
      <div className=" mx-auto">
        <div className="grid sm:grid-cols-2 sm:grid-flow-col mt-10">
          <div className="md:flex-1 ">
            <div className="text-7xl tracking-1px font-extrabold md:flex-1 justify-center">
              <h1 className="mt-2 lg:mt-0 text-center text-primary font-pat tracking-widest">
                James
              </h1>
              <h1 className="text-secondary text-center font-pat tracking-widest">
                Wingert
              </h1>
            </div>
            {/* <div className="grid bg-base-300 h-48"> */}
            <div className="text-lg mx-auto flex text-primary flex-col gap-4 rounded-3xl max-w-lg mt-8  ">
              {" "}
              <h3 className="text-lg tracking-tighter  font-normal leading-9 mt-4 ml-2 ">
                JamesWingert/README.md
              </h3>
              <div className="flex text-center text-primary items-center font-normal">
                <span className="h-8 w-8 text-3xl  text-center items-center inline-flex justify-center mr-2 ml-4 ">
                  🖥{" "}
                </span>
                Software Developer
              </div>
              <div className="flex items-center text-primary font-normal mr-2">
                <span className="h-8 w-8 text-3xl  text-center items-center inline-flex justify-center mr-2 ml-4">
                  🎓
                </span>
                B.S. Computer Science
              </div>
              <div className="flex items-center text-primary text-center font-normal">
                <span className="h-8 w-8 text-3xl  text-center items-center inline-flex justify-center mr-2 ml-4">
                  🌞
                </span>
                Phoenix, Arizona
              </div>
              <div className="flex items-center text-primary font-normal mr-2">
                <span className="h-8 w-8 text-3xl  text-center items-center inline-flex justify-center mr-2 ml-4">
                  🛠
                </span>
                Utilizing technology as a tool to improve the quality of life
                for others.
              </div>
              <div className="flex items-center text-primary font-normal  mr-2">
                <span className="h-8 w-8 text-3xl  text-center items-center inline-flex justify-center mr-2 ml-4">
                  🐶
                </span>
                In my free time I enjoy basketball, finance, learning new
                things, and dogs!
              </div>
              {/* <div className="flex items-center font-normal mr-2">
                <span className="h-8 w-8 text-3xl text-center items-center inline-flex justify-center mr-2 ml-4">
                  ✉️
                </span>
                JamesPWingert@gmail.com
              </div> */}
              <div className="mb-8 ml-4 text-start items-start justify-start  mt-4">
                <a href="#contact">
                  <button className="btn  hover:bg-white hover:text-neutral-content text-white  w-48 text-xl bg-neutral-content">
                    Hire Me
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="mt-10  mx-auto flex-1  ">
            <img className="rounded-full mx-auto " src={me3} alt="hero" />
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
}
