/* eslint-disable react/jsx-key */
import tennis from '../assets/tennis.png';
import neuro from '../assets/NeuroSite.png';
import vrp from '../assets/jwvrp.png';
import js from '../assets/js.svg';
import rt from '../assets/react.png';
import mdb from '../assets/mdb.webp';
import py from '../assets/py.png';
import nt from '../assets/nxt.png';
import ql from '../assets/ql.png';
import dk from '../assets/dk.png';
import psql from '../assets/psql.png';
import ts from '../assets/ts.svg';
import tw from '../assets/tw.png';
import e from '../assets/e.png';
import html from '../assets/html.png';
import css from '../assets/css.png';
import aws from '../assets/aws.png';
import React from 'react';
import levelup from '../assets/levelup.png';

const projects = [
  {
    name: 'Neurology Website',
    image: `${neuro}`,
    skills: [js, nt, ts, tw, html, css],
    site: 'www.neurologyandsleep.com',
    git: 'https://github.com/JamesWingert/NeuroSite',
    desc: 'Website created for a local Doctor office as a client. Worked with the client to create and design the website exactly as desired. Functioning forms, information about the practice, mobile friendly, 3rd party integration for HIPAA forms, functioning Google Maps, patient portal integration, tables, and other requested features.',
  },
  {
    name: 'Tennis Benchmark',
    image: `${tennis}`,
    skills: [py, nt, ts, psql, tw],
    site: 'app.tennisbenchmark.com',
    git: '',
    desc: 'An app created to assist a high-school tennis coach with tracking his players performance. Contains an admin dashboard the coach, individual logins based on SSO for the players, data tracking, and much more. The frontend is built on NextJS and the backend is built on Django.',
  },
  {
    name: 'Level Up Fashion',
    image: `${levelup}`,
    skills: [js, nt, ts, ql, psql, tw, dk, aws],
    site: 'https://level-up-fashion.vercel.app/',
    git: 'https://github.com/JamesWingert/Level-Up-Fashion',
    desc: 'A community fashion blog where users are able to share fashion ideas as posts containing images, links, titles and descriptions. Authentication is handled by Auth0 and the site is hosted on Vercel with image hosting by AWS.',
  },
  {
    name: 'Vehicle Routing Program',
    image: `${vrp}`,
    skills: [py],
    site: 'https://github.com/JamesWingert/Vehicle-Routing-Program',
    git: 'https://github.com/JamesWingert/Vehicle-Routing-Program',
    desc: 'Command line application created to solve the issue of finding the most optimal routes for delivery drivers given specific criteria. Utilizes custom hash-table, nearest neighbor algorithm, and no external libraries. Ability to look up where a single package or all packages are at any given time. Records how quickly the trucks were able to perform their deliveries.',
  },
];

export default function Projects() {
  return (
    <div id='projects'>
      <div className='max-w-7xl mx-auto py-20 px-4 text-center sm:px-6 lg:px-8  '>
        <div className='space-y-8 sm:space-y-12 '>
          <div className='space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl'>
            {' '}
            <h2 className='text-3xl font-semibold text-primary tracking-widest sm:text-6xl font-oleo uppercase'>
              Recent Projects
            </h2>
            <p className='text-xl text-neutral'>
              These projects contain both personal projects and projects
              constructed for clients. I am capable of creating backend,
              frontend, or fullstack applications.
            </p>
          </div>
          <section className='mx-auto bg-base-300'>
            {projects.map((project) => {
              return (
                <div className='grid items-center grid-cols-1 mb-12 md:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24 p-10'>
                  <div>
                    <h2 className='mb-4 text-2xl font-extrabold tracking-tight text-center text-secondary md:leading-tight sm:text-left md:text-4xl'>
                      {project.name}
                    </h2>
                    <div className='flex mb-4 gap-2'>
                      {project.skills.map((skill) => {
                        return (
                          <img
                            alt=''
                            //@ts-ignore
                            src={[`${skill}`]}
                            className='bg-base-100 h-7 w-7 rounded-full'
                          />
                        );
                      })}
                    </div>
                    <p className='mb-5 text-base text-center text-primary sm:text-left md:text-lg'>
                      {project.desc}
                    </p>
                    <div className='flex flex-col sm:flex-row justify-center md:justify-start gap-4'>
                      <a href={project.site}>
                        <button
                          //@ts-ignore

                          className='btn  hover:bg-white hover:text-neutral-content text-white  w-36 text-xl bg-neutral-content'
                        >
                          View Site
                        </button>
                      </a>
                      <a href={project.git}>
                        <button
                          //@ts-ignore

                          className='btn  hover:bg-white hover:text-neutral-content text-white  w-54 text-xl bg-neutral-content'
                        >
                          View GitHub Repo
                        </button>
                      </a>
                    </div>
                  </div>
                  <div className='mockup-window border bg-base-100'>
                    <img
                      alt=''
                      src={project.image}
                      className='flex justify-center border-t'
                    />
                  </div>
                </div>
              );
            })}
          </section>
        </div>
      </div>
    </div>
  );
}
