import { Disclosure } from "@headlessui/react";
import {
  AiOutlineGithub,
  AiOutlineLinkedin,
  AiFillExperiment,
  AiOutlineCode,
  AiOutlinePhone,
} from "react-icons/ai";
import React from "react";

import { FaSun, FaMoon } from "react-icons/fa";
import { GiPaperClip } from "react-icons/gi";
import { useTheme } from "react-daisyui";
import { useState } from "react";

const navigation = [
  {
    name: "Skills",
    href: "#skills",
    current: false,
    icon: <AiFillExperiment className="h-7 w-7 mr-1" />,
  },
  {
    name: "Projects",
    href: "#projects",
    current: false,
    icon: <AiOutlineCode className="h-7 w-7 mr-1" />,
  },
  {
    name: "Contact",
    href: "#contact",
    current: false,
    icon: <AiOutlinePhone className="h-7 w-7 mr-1" />,
  },

  {
    name: "GitHub",
    href: "https://github.com/JamesWingert",
    current: false,
    icon: <AiOutlineGithub className="h-7 w-7 mr-1" />,
  },
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/in/james-wingert/",
    current: false,
    icon: <AiOutlineLinkedin className="h-7 w-7 mr-1" />,
  },
  {
    name: "Resume",
    href: "https://drive.google.com/file/d/1Hr1-OmVKs-M8wd_Pham_bLxZW232VOEj/view?usp=sharing",
    current: false,
    icon: <GiPaperClip className="h-7 w-7 mr-1" />,
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const { theme, setTheme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const genericHamburgerLine = `h-1 w-6 my-[.15rem] rounded-full bg-primary sm:transition sm:ease sm:transform sm:duration-300 `;

  return (
    // eslint-disable-next-line react/jsx-no-comment-textnodes
    <Disclosure as="nav" className="mt-2 sticky top-0 z-50 bg-base-100">

      {() => (
        <>
          <div className="max-w-7xl mx-auto px-2 ">
            <div className="relative flex items-center justify-between text-center h-16">
              <div className="absolute inset-y-0 right-0 flex items-center text-center lg:hidden ">
                {" "}
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white ">
                  <span className="sr-only">Open main menu</span>
                  <button
                    className="flex flex-col h-9 w-8   rounded justify-center items-center group"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <div
                      className={`${genericHamburgerLine} ${
                        isOpen
                          ? "rotate-45 translate-y-[.4rem] opacity-50 group-hover:opacity-100"
                          : "opacity-50 group-hover:opacity-100"
                      }`}
                    />
                    <div
                      className={`${genericHamburgerLine} ${
                        isOpen
                          ? "opacity-0"
                          : "opacity-50 group-hover:opacity-100"
                      }`}
                    />
                    <div
                      className={`${genericHamburgerLine} ${
                        isOpen
                          ? "-rotate-45 -translate-y-3 opacity-50 group-hover:opacity-100"
                          : "opacity-50 group-hover:opacity-100"
                      }`}
                    />
                  </button>
                </Disclosure.Button>
              </div>
              <a href="/">
                <div className="flex-shrink-0 flex text-center text-primary text-5xl font-serif tracking-tighter  font-semibold font-logo ">
                  J<span className="text-secondary">W</span>
                </div>
              </a>
              <div className="justify-center items-center flex text-center">
                <label className="swap swap-rotate">
                  <input type="checkbox" id="swap" />
                  {theme === "dracula" ? (
                    <FaSun
                      onClick={() =>
                        setTheme(theme === "dracula" ? "cmyk" : "dracula")
                      }
                      className="text-accent dark:text-accent text-4xl cursor-pointer swap-on"
                    />
                  ) : (
                    <FaMoon
                      onClick={() =>
                        setTheme(theme === "dracula" ? "cmyk" : "dracula")
                      }
                      className="text-primary text-4xl cursor-pointer swap-off"
                    />
                  )}
                </label>
              </div>
              <div className="relative  flex items-center  sm:static inset-auto ">
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-end">
                  <div className="hidden lg:block md:ml-4">
                    <div className="flex space-x-3 ">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            "text-primary hover:bg-neutral-content hover:text-white hover:scale-110 hover:rotate-6",
                            "px-2 py-2 rounded-md text-xl text-center  items-center font-medium flex"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {/* {item.icon} */}
                          {item.name}
                        </a>
                      ))}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => setIsOpen(!isOpen)}
            className={
              isOpen
                ? "z-40 fixed top-0 left-0 bg-black bg-opacity-75 h-full w-full lg:hidden ease-in-out transform translate-x-0 transition duration-150 "
                : "z-40 transition duration-150 ease-in-out transform -translate-x-full "
            }
          >
            {" "}
            <div className="absolute z-50 bg-base-300 -mb-.5 w-64 h-full">
              {" "}
              <div
                className={
                  isOpen
                    ? "pt-10 px-1 space-y-3 z-40 fixed  top-0 left-0 w-64 flex-col justify-between transition duration-150 ease-in-out transform  translate-x-0 lg:hidden"
                    : "hidden"
                  // left-0 w-64  fixed z-40 top-0  shadow  flex-col justify-between pb-12 transition duration-50 ease-in-out transform  -translate-x-full
                }
              >
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    onClick={() => setIsOpen(!isOpen)}
                    className={classNames(
                      "text-primary text-center items-center hover:bg-neutral-content  hover:text-white",
                      "px-3 py-2 rounded-md text-xl font-medium flex bg-base-300 "
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.icon}

                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </div>{" "}
          </div>
        </>
      )}
    </Disclosure>
  );
}
