import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import { useState } from "react";
import emailjs from "@emailjs/browser";
import Alert from "./Alert";
import React from "react";

type ContactProps = {
  fullname: any;
  email: string | boolean;
  phone: string | boolean;
  message: string | boolean;
  tempErrors: {} | null;
};
export default function Contact() {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState<ContactProps>();
  const [subject] = useState("Contact Form");

  //   Setting button text on form submission
  const [buttonText, setButtonText] = useState("Submit");

  // Setting success or failure messages states
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showFailureMessage, setShowFailureMessage] = useState(false);

  // Validation check method
  // Validation check method
  const handleValidation = () => {
    let tempErrors: ContactProps = {} as ContactProps;

    let isValid = true;

    if (fullname.length <= 0) {
      tempErrors["fullname"] = true;
      isValid = false;
    }
    if (email.length <= 0) {
      tempErrors["email"] = true;
      isValid = false;
    }
    if (phone.length <= 0) {
      tempErrors["phone"] = true;
      isValid = false;
    }
    if (message.length <= 0) {
      tempErrors["message"] = true;
      isValid = false;
    }
    //@ts-ignore
    setErrors({ ...tempErrors });
    console.log("errors", errors);
    return isValid;
  };
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    let isValidForm = handleValidation();

    if (isValidForm) {
      setButtonText("Sending");

      let templateParams = {
        fullname: fullname,
        email: email,
        phone: phone,
        subject: subject,
        message: message,
      };
      emailjs
        .send(
          `${process.env.REACT_APP_SERVICE_ID}`,
          `${process.env.REACT_APP_TEMPLATE}`,
          templateParams,
          `${process.env.REACT_APP_PUBLIC_ID}`
        )
        .then(
          (response) => {
            console.log(response.text);
            setShowSuccessMessage(true);
            setShowFailureMessage(false);
            setButtonText("Submit");
            // Reset form fields
            setFullname("");
            setEmail("");
            setPhone("");
            setMessage("");
          },
          (error) => {
            console.log(error.text);
            setShowSuccessMessage(false);
            setShowFailureMessage(true);
            setButtonText("Submit");
            setFullname("");
            setEmail("");
            setPhone("");
            setMessage("");
            return;
          }
        );
    }
    console.log(fullname, email, phone, message);
  };
  return (
    <div id="contact" className="py-20">
      <div className="max-w-7xl mx-auto px-4 text-center sm:px-6 lg:px-8 ">
        {" "}
        <h2 className="text-3xl font-semibold text-primary tracking-widest sm:text-6xl font-oleo uppercase mb-16">
          Reach Out
        </h2>
        <div className="relative bg-base-300 rounded-2xl">
          <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
            <div className=" py-8 px-4 sm:px-6 lg:col-span-2 lg:px-8 xl:pr-12">
              <div className="max-w-lg mx-auto space-y-4">
                <p className="space-y-4 text-lg leading-6 text-primary">
                  Looking to have a website built? <br />
                  <br />
                  Potential employer? <br />
                  <br />
                  Want to hang out and chat? <br />
                  <br />
                  <br />
                  Please don&apos;t hesitate to reach out! <br />I look forward
                  to hearing from you.
                </p>
                <dl className="text-base text-primary">
                  <div className="mt-6">
                    <dt className="sr-only">Phone number</dt>
                    <dd className="flex text-secondary">
                      <PhoneIcon
                        className="flex-shrink-0 h-6 w-6"
                        aria-hidden="true"
                      />
                      <span className="ml-3 text-primary">
                        +1 (480) 747-8296
                      </span>
                    </dd>
                  </div>
                  <a href="mailto:jamespwingert@gmail.com">
                    <div className="mt-3 ">
                      <dt className="sr-only">Email</dt>
                      <dd className="flex text-secondary hover:text-primary-content">
                        <MailIcon
                          className="flex-shrink-0 h-6 w-6 "
                          aria-hidden="true"
                        />

                        <span className="ml-3 text-primary underline-offset-8 underline hover:text-primary-content">
                          JamesPWingert@gmail.com
                        </span>
                      </dd>
                    </div>
                  </a>
                </dl>
              </div>
            </div>

            <div className="bg-base-100 py-8 px-4 sm:px-6 lg:col-span-3 lg:px-8 xl:pl-12 border-base-300 border-2 rounded-md ">
              <div className="max-w-lg mx-auto lg:max-w-none">
                <form
                  onSubmit={handleSubmit}
                  className="grid grid-cols-1 gap-y-6"
                >
                  <div>
                    {" "}
                    <input
                      type="hidden"
                      name="_subject"
                      value="Feedback Form"
                    />
                    <label htmlFor="fullname" className="sr-only">
                      Full Name
                    </label>
                    <input
                      type="text"
                      name="fullname"
                      id="fullname"
                      autoComplete="name"
                      className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 border-primary/10 border-2 ring-black rounded-lg  focus:outline-none focus:ring-2 focus:ring-secondary/70"
                      placeholder="Full Name"
                      value={fullname}
                      onChange={(e) => {
                        setFullname(e.target.value);
                      }}
                    />
                    {errors?.fullname && (
                      <div>
                        <Alert message="Full Name cannot be empty." />
                      </div>
                    )}
                  </div>
                  <div>
                    <label htmlFor="email" className="sr-only">
                      Email
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 border-primary/10 border-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-secondary/70"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />{" "}
                    {errors?.email && (
                      <div className=" ">
                        <Alert message="Email cannot be empty." />
                      </div>
                    )}
                  </div>
                  <div>
                    <label htmlFor="phone" className="sr-only">
                      Phone
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      autoComplete="tel"
                      className="block w-full shadow-sm py-3 px-4 placeholder-gray-500  border-primary/10 border-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-secondary/70"
                      placeholder="Phone"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                    {errors?.phone && (
                      <div className=" ">
                        <Alert message="Phone cannot be empty." />
                      </div>
                    )}
                  </div>
                  <div>
                    <label htmlFor="message" className="sr-only">
                      Message
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      rows={8}
                      className="block w-full shadow-sm py-3 px-4 placeholder-gray-500  border-primary/10 border-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-secondary/70"
                      placeholder="Message"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    />{" "}
                    {errors?.message && (
                      <div className=" ">
                        <Alert message="Message cannot be empty." />
                      </div>
                    )}
                  </div>
                  <div className="flex gap-4 text-center items-center justify-center">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-6 btn  hover:bg-white hover:text-neutral-content text-white  w-48 text-xl bg-neutral-content"
                    >
                      {buttonText}
                    </button>
                    <div className="text-left">
                      {showSuccessMessage && (
                        <p className="text-green-500 font-semibold text-lg my-2">
                          Thank you! Your Message has been delivered.
                        </p>
                      )}
                      {showFailureMessage && (
                        <div className=" ">
                          <Alert message="Oops! Something went wrong. Please try again later." />
                        </div>
                      )}
                    </div>
                  </div>{" "}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
