import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Skills from './components/Skills';
import { Theme } from 'react-daisyui';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';
import React from 'react';

function App() {
  return (
    <Theme dataTheme='cmyk' className='bg-base-300 h-fill lg:py-12 mx-auto'>
      {/* <div class=" lg:py-16 "> */}
      <div className='bg-base-100 h-fill  md:max-w-6xl mx-auto sm:rounded-lg p-2 md:p-8  md:shadow-2xl space-y-16 '>
        <Navbar />
        <Hero />
        <Skills />
        <Projects />
        <Contact />
        <Footer />
      </div>
    </Theme>
  );
}

export default App;
